import axios from "@/utils/request";

const api = {
  // 提交某个客户(卖家)的调查信息
  submitInvestigate(data) {
    return axios({
      url: "/fin-portal/controller/customer/investigate/submit",
      method: "POST",
      data,
    });
  },
  // 获取开店平台类型
  getPlatformTypeList(data) {
    return axios({
      url: "/fin-portal/controller/customer/config/platformType_config",
      method: "GET",
      data,
    });
  },
  // 调查表详情
  loadInvestigate(data) {
    return axios({
      url: "/fin-portal/controller/customer/investigate/load",
      method: "POST",
      data,
    });
  },
  // 发送短信二维码
  sendSmsCode(data) {
    return axios({
      url: "/fin-portal/controller/auth/user/send_sms_code",
      method: "POST",
      data,
    });
  },
  // 发送邮箱二维码
  sendEmailCode(data) {
    return axios({
      url: "/fin-portal/controller/auth/user/send_email_code",
      method: "POST",
      data,
    });
  },
  // 卖家登录
  login_customer(data) {
    return axios({
      url: "/fin-portal/controller/auth/user/login_customer",
      method: "POST",
      data,
    });
  },
  // 卖家注册
  register(data) {
    return axios({
      url: "/fin-portal/controller/auth/user/register",
      method: "POST",
      data,
    });
  },
  // 邮件发送激活链接
  sendEmailActive(data) {
    return axios({
      url: "/fin-portal/controller/auth/user/send_email_active",
      method: "POST",
      data,
    });
  },
  // 邮箱激活
  activeEmail({ id, uid }) {
    return axios({
      url: `/fin-portal/controller/auth/user/active_mail?id=${id}&uid=${uid}`,
      method: "POST",
    });
  },
  // 忘记密码
  findPassword(data) {
    return axios({
      url: "/fin-portal/controller/auth/user/find_pwd",
      method: "POST",
      data,
    });
  },
  // 卖家退出登录
  logout(data) {
    return axios({
      url: "/fin-portal/controller/auth/user/logout",
      method: "POST",
      data,
    });
  },
  // 滑块验证码相关 1. 验证码验证接口
  captchaValidate(data) {
    return axios({
      url: "/fin-portal/controller/auth/captcha/validate",
      method: "POST",
      data,
    });
  },
  // 查询卖家详情（包括 企业、店铺）
  getCustomerInfo(data) {
    return axios({
      url: "/fin-portal/controller/customer/customer/load_mobile",
      method: "POST",
      data,
    });
  },
  // 用户信息-获取卖家中心-当前登录用户信息
  getCustomerDetail(noAuth) {
    return axios({
      url: "/fin-portal/controller/auth/user/load_customer",
      method: "GET",
      noAuth,
    });
  },
  // 判断当前邀请url的有效性
  validateInvitation(data) {
    return axios({
      url: "/fin-portal/controller/customer/channel/validate",
      method: "POST",
      data,
    });
  },
  // 提交用户调查信息表信息(H5)
  submitInvestigateH5(data) {
    return axios({
      url: "/fin-portal/controller/customer/investigate/submit_h5",
      method: "POST",
      data,
    });
  },
};

export default api;
