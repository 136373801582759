import Vue from "vue";
import { Message, MessageBox } from "element-ui";
import ElementUI from "element-ui";
import Vant from "vant";
import VueAwesomeSwiper from "vue-awesome-swiper";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "swiper/css/swiper.css";
import "element-ui/lib/theme-chalk/index.css";
import "vant/lib/index.css";
import "@/style/reset.less";
import "@/style/tailwindcss.css";

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;

ElementUI.Table.props.headerCellStyle = {
  type: [Object, Function],
  default: () => {
    return {
      backgroundColor: "#f0f0f0",
      color: "#333",
    };
  },
};
Vue.use(ElementUI);
Vue.use(Vant);

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vm;
