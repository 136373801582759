import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
    component: () => import("@/components/Layout"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "fusion",
        name: "welcome",
        component: () => import("../views/fusion/fusion.vue"),
      },
      {
        path: "user",
        name: "user",
        component: () => import("../views/user/user.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/register.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/agreement/agreement.vue"),
  },
  {
    path: "/invitation",
    name: "invitation",
    component: () => import("../views/invitation/Home/index.vue"),
  },
  {
    path: "/invitation/result",
    name: "invitationResult",
    component: () => import("../views/invitation/Result/index.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
